import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'
import SubHeading from './sub-heading'
import TextBlock from './text-block'
import TeamMember from './team-member'

export default function TeamSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicTheTeamPage {
        data {
          page_heading {
            text
          }
          team_photo_mobile {
            alt
            url
            dimensions {
              width
              height
            }
          }
          team_photo_tablet {
            alt
            url
            dimensions {
              width
              height
            }
          }
          team_photo_desktop {
            alt
            url
            dimensions {
              width
              height
            }
          }
          team_intro_text {
            html
          }
          team_members {
            name {
              text
            }
            role {
              text
            }
            profile_photo {
              alt
              url
              dimensions {
                width
                height
              }
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    heading: data.prismicTheTeamPage.data.page_heading.text,
    mobileTeamPhoto: data.prismicTheTeamPage.data.team_photo_mobile,
    tabletTeamPhoto: data.prismicTheTeamPage.data.team_photo_tablet,
    desktopTeamPhoto: data.prismicTheTeamPage.data.team_photo_desktop,
    introText: data.prismicTheTeamPage.data.team_intro_text.html,
    members: data.prismicTheTeamPage.data.team_members,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <Container type="wide">
        <div className="row">
          <hr />
          <SubHeading heading={prismicData.heading} />
          <Fade>
            <picture className="top-image">
              <source media="(min-width: 900px)" srcSet={prismicData.desktopTeamPhoto.url} />
              <source media="(min-width: 600px)" srcSet={prismicData.tabletTeamPhoto.url} />
              <source media="(min-width: 0px)" srcSet={prismicData.mobileTeamPhoto.url} />
              <img
                src={prismicData.tabletTeamPhoto.url}
                alt={prismicData.tabletTeamPhoto.alt}
                width={prismicData.tabletTeamPhoto.dimensions?.width}
                height={prismicData.tabletTeamPhoto.dimensions?.height}
              />
            </picture>
          </Fade>
          {prismicData.introText && (
            <Fade>
              <div className="intro-text">
                <TextBlock>
                  <div dangerouslySetInnerHTML={{__html: prismicData.introText}} />
                </TextBlock>
              </div>
            </Fade>
          )}
          {prismicData.members.length > 0 && (
            <ul className="members-wrapper">
              {prismicData.members.map((item, index) => (
                <TeamMember
                  key={index}
                  name={item.name.text}
                  role={item.role.text}
                  photoUrl={item.profile_photo.url}
                  photoAlt={item.profile_photo.alt}
                  photoWidth={item.profile_photo.dimensions?.width}
                  photoHeight={item.profile_photo.dimensions?.height}
                />
              ))}
            </ul>
          )}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 4rem;

  .row {
    margin: 0 auto 4rem auto;
    max-width: 90rem;
    width: 100%;
  }

  hr {
    margin-bottom: 4rem;
  }

  .top-image {
    display: block;
    margin: 0 0 4rem 0;
  }

  .intro-text {
    margin: 0 0 4rem 0;
  }

  .members-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr;

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 850px) {
      gap: 5rem;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
