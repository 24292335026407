import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import Fade from 'react-reveal/Fade'
import PageHeading from './page-heading'
import QuoteBlock from './quote-block'
import React from 'react'
import styled from 'styled-components'
import TextBlock from './text-block'

export default function StudioSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicAboutPage {
        data {
          page_heading {
            text
          }
          top_image_mobile {
            alt
            url
            dimensions {
              height
              width
            }
          }
          top_image_tablet {
            alt
            url
            dimensions {
              width
              height
            }
          }
          top_image_desktop {
            alt
            url
            dimensions {
              width
              height
            }
          }
          intro_text {
            html
          }
          body_copy {
            html
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicAboutPage.data.page_heading.text,
    mobileImage: data.prismicAboutPage.data.top_image_mobile,
    tabletImage: data.prismicAboutPage.data.top_image_tablet,
    desktopImage: data.prismicAboutPage.data.top_image_desktop,
    introText: data.prismicAboutPage.data.intro_text.html,
    bodyCopy: data.prismicAboutPage.data.body_copy.html,
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide">
        <div className="row">
          <Fade>
            <picture className="top-image">
              <source media="(min-width: 900px)" srcSet={prismicData.desktopImage.url} />
              <source media="(min-width: 600px)" srcSet={prismicData.tabletImage.url} />
              <source media="(min-width: 0px)" srcSet={prismicData.mobileImage.url} />
              <img
                src={prismicData.tabletImage.url}
                alt={prismicData.tabletImage.alt}
                width={prismicData.tabletImage.dimensions?.width}
                height={prismicData.tabletImage.dimensions?.height}
              />
            </picture>
          </Fade>
          {prismicData.introText && (
            <Fade>
              <div className="intro-text">
                <TextBlock>
                  <div dangerouslySetInnerHTML={{__html: prismicData.introText}} />
                </TextBlock>
              </div>
            </Fade>
          )}
          {prismicData.bodyCopy && (
            <Fade>
              <div className="body-copy">
                <div dangerouslySetInnerHTML={{__html: prismicData.bodyCopy}} />
              </div>
            </Fade>
          )}
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .row {
    margin: 0 auto 4rem auto;
    max-width: 90rem;
    width: 100%;
  }

  .top-image {
    display: block;
    margin: 0 0 4rem 0;
  }

  .intro-text {
    margin: 0 0 4rem 0;
  }

  .body-copy {
    margin: 0 0 4rem 0;
    max-width: 70rem;

    em {
      display: block;
      font-style: normal;
      font-weight: 400;
      max-width: 56rem;
      padding: 0 3rem;
      position: relative;

      &::before {
        content: '“';
        display: block;
        font-size: 4rem;
        font-weight: 700;
        left: 0;
        position: absolute;
        top: 0;
        line-height: 1;
      }

      &::after {
        content: '”';
        display: block;
        font-size: 4rem;
        font-weight: 700;
        right: 0;
        position: absolute;
        top: 0;
        line-height: 1;
      }
    }
  }
`
