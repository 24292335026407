import Fade from 'react-reveal/Fade'
import React from 'react'
import styled from 'styled-components'

export default function TeamMember({name, role, photoUrl, photoAlt, photoWidth, photoHeight}) {
  return (
    <StyledComponent>
      <Fade>
        <div>
          <img src={photoUrl} alt={photoAlt} width={photoWidth} height={photoHeight} />
          <div className="details">
            <p className="name">{name}</p>
            <p className="role">{role}</p>
          </div>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img {
    align-self: center;
    margin: 0 auto;
    max-width: 30rem;
  }

  .details {
    border-left: 1px solid black;
    padding: 1rem;
  }

  p {
    margin: 0 0 0 0;

    &.name {
      font-weight: 600;
    }

    &.role {
      font-style: italic;
      font-size: 1.3rem;
    }
  }
`
