import {graphql} from 'gatsby'
import StudioSection from '../components/studio-section'
import ContactSection from '../components/contact-section'
import HoldingPageHeroImage from '../components/holding-page-hero-image'
import Layout from '../components/layout'
import React, {useState} from 'react'
import TeamSection from '../components/team-section'

import {
  STUDIO_SECTION_CSS_MODIFIER,
  HOLDING_PAGE_CSS_MODIFIER,
  PAGE_ROOT_ELEMENT_ID,
  TEAM_SECTION_CSS_MODIFIER,
} from '../constants/global-constants'

export default props => {
  const {data} = props
  const prismicData = {
    showTheHoldingPage: data.prismicHoldingPage.data.show_the_holding_page,
  }

  return (
    <Layout
      documentTitlePrefix="STUDIO"
      isIndexPage={false}
      pageName="studio"
      showScrollToTopBtn={false}
      showTheHoldingPage={prismicData.showTheHoldingPage}
    >
      {prismicData.showTheHoldingPage ? (
        <>
          <HoldingPageHeroImage />
          <ContactSection cssModifier={HOLDING_PAGE_CSS_MODIFIER} />
        </>
      ) : (
        <>
          <StudioSection cssModifier={STUDIO_SECTION_CSS_MODIFIER} />
          <TeamSection cssModifier={TEAM_SECTION_CSS_MODIFIER} />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    prismicHoldingPage {
      data {
        show_the_holding_page
      }
    }
  }
`
