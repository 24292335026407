import React from 'react'
import styled from 'styled-components'

export default function QuoteBlock({cssModifier, quoteText, sourceText}) {
  return (
    <StyledComponent className={`quote-block ${cssModifier ? `quote-block--${cssModifier}` : ''}`}>
      <figure className="quote-block-inner">
        <blockquote dangerouslySetInnerHTML={{__html: quoteText}} />
        {sourceText && <figcaption>{sourceText}</figcaption>}
      </figure>
    </StyledComponent>
  )
}

const StyledComponent = styled.figure`
  margin: 0;

  .quote-block-inner {
    margin: 0;
    padding: 2rem 2rem;

    @media (min-width: 600px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  blockquote {
    margin: 0 0 2rem 0;

    strong {
      font-weight: 600;
    }
  }

  figcaption {
    font-weight: 400;
    font-size: 1.4rem;
  }
`
